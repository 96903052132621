.linkButton {
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #fff;
  margin-right: 0.5rem;
}

.linkButton:hover {
  color: #f7ddbd;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .header {
    background: #fff;
    padding: 1.5rem 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header h1 {
    font-size: 1.75rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
    position: relative;
    padding-bottom: 0.5rem;
  }
  
  .header h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: #3b82f6;
    border-radius: 2px;
  }
  
  .uploadSection {
    display: flex;
    align-items: center;
  }
  
  .fileInput {
    display: none;
  }
  
  .uploadButton {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: #fff;
    color: #3b82f6;
    padding: 0.75rem 1.5rem;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid rgba(59, 130, 246, 0.2);
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
  }
  
  .uploadButton:hover {
    background: #3b82f6;
    color: #fff;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
  }
  
  .content {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
  }
  
  .variantsList {
    background: #fff;
    padding: 1.5rem;
    border-radius: 16px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .variantsList h2 {
    font-size: 1.25rem;
    color: #111827;
    margin-bottom: 1.5rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.5rem;
  }
  
  .variantsList h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 2px;
    background: #3b82f6;
    border-radius: 2px;
  }
  
  .variantItem {
    background: #f9fafb;
    padding: 1rem;
    border-radius: 12px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid #e5e7eb;
  }
  
  .variantItem:hover {
    border-color: #3b82f6;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
  }
  
  .selectedVariant {
    background: rgba(59, 130, 246, 0.05);
    border-color: #3b82f6;
  }
  
  .variantInfo {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .variantName {
    font-weight: 600;
    color: #111827;
  }
  
  .variantDetails {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .imagesSection {
    background: #fff;
    padding: 1.5rem;
    border-radius: 16px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .imagesSection h2 {
    font-size: 1.25rem;
    color: #111827;
    margin: 2rem 0 1.5rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.5rem;
  }
  
  .imagesSection h2:first-child {
    margin-top: 0;
  }
  
  .imagesSection h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 2px;
    background: #3b82f6;
    border-radius: 2px;
  }
  
  .variantImages,
  .imageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .variantImageCard,
  .imageCard {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: #f9fafb;
    height: 250px;
    transition: all 0.2s ease;
    border: 1px solid #e5e7eb;
  }
  
  .variantImageCard:hover,
  .imageCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .variantImageCard img,
  .imageCard img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 0.5rem;
    background: #fff;
  }
  
  .variantImageCard::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, transparent 70%, rgba(0, 0, 0, 0.1));
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .variantImageCard:hover::after {
    opacity: 1;
  }
  
  .removeButton {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    background: rgba(239, 68, 68, 1);
    color: #ffffff;
    transition: all 0.2s ease;
    opacity: 0;
    white-space: nowrap;
    z-index: 10;
  }
  
  .variantImageCard:hover .removeButton {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  
  .variantImageCard .removeButton:hover {
    background: #ef4444;
    color: #fff;
  }
  
  .imageActions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.3s ease;
  }
  
  .imageCard:hover .imageActions {
    opacity: 1;
    transform: translateY(0);
  }
  
  .selectButton,
  .deleteButton {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .selectButton {
    background: rgba(16, 185, 129, 0.1);
    color: #10b981;
  }
  
  .selectButton:hover {
    background: #10b981;
    color: #fff;
  }
  
  .deleteButton {
    background: rgba(239, 68, 68, 0.2);
    color: #ef4444;
  }
  
  .deleteButton:hover {
    background: #ef4444;
    color: #fff;
  }
  
  .noVariantSelected {
    text-align: center;
    padding: 2rem;
    color: #6b7280;
    background: #f9fafb;
    border-radius: 12px;
    border: 2px dashed #e5e7eb;
    font-size: 0.9375rem;
  }
  
  .error {
    margin-bottom: 1.5rem;
    padding: 1rem 1.5rem;
    background: #fff;
    border-radius: 12px;
    border-left: 4px solid #ef4444;
    color: #dc2626;
    font-size: 0.9375rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    color: #6b7280;
    font-size: 0.9375rem;
  }
  
  @media (max-width: 1024px) {
    .content {
      grid-template-columns: 250px 1fr;
      gap: 1.5rem;
    }
    
    .variantImageCard,
    .imageCard {
      height: 220px;
    }
  }
  
  @media (max-width: 768px) {
    .content {
      grid-template-columns: 1fr;
    }
    
    .header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
      padding: 1.25rem;
    }
    
    .header h1 {
      font-size: 1.5rem;
    }
    
    .header h1::after {
      left: 50%;
      transform: translateX(-50%);
    }
    
    .variantImageCard,
    .imageCard {
      height: 200px;
    }
    
    .imagesSection {
      padding: 1.25rem;
    }
    
    .variantImages,
    .imageGrid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 1rem;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .variantImages,
  .imageGrid {
    animation: fadeIn 0.3s ease-out;
  }

/* for upload pending state */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.container {
  max-width: 1400px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #111827;
  position: relative;
  padding-bottom: 0.5rem;
}

.title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: #3b82f6;
  border-radius: 2px;
}

.addButton {
  display: inline-flex;
  align-items: center;
  background: #fff;
  color: #3b82f6;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.addButton:hover {
  background: #3b82f6;
  color: #fff;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
}

.table {
  width: 100%;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
}

.tableHeader {
  padding: 1.25rem 1.5rem;
  background: #fff;
  font-weight: 600;
  color: #4b5563;
  text-align: left;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 1px solid rgba(229, 231, 235, 0.7);
}

.tableCell {
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(229, 231, 235, 0.7);
  color: #374151;
  font-size: 0.9375rem;
}

.tableRow {
  transition: all 0.2s ease;
}

.tableRow:last-child .tableCell {
  border-bottom: none;
}

.tableRow:hover {
  background: rgba(249, 250, 251, 0.8);
}

.actionButtons {
  display: flex;
  gap: 0.75rem;
}

.actionButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

/* Style variants for different action buttons */
.actionButton[data-type="primary"] {
  background: rgba(59, 130, 246, 0.1);
  color: #3b82f6;
}

.actionButton[data-type="primary"]:hover {
  background: rgba(59, 130, 246, 0.15);
}

.actionButton[data-type="warning"] {
  background: rgba(245, 158, 11, 0.1);
  color: #f59e0b;
}

.actionButton[data-type="warning"]:hover {
  background: rgba(245, 158, 11, 0.15);
}

.actionButton[data-type="info"] {
  background: rgba(14, 165, 233, 0.1);
  color: #0ea5e9;
}

.actionButton[data-type="info"]:hover {
  background: rgba(14, 165, 233, 0.15);
}

.deleteButton {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.deleteButton:hover {
  background: rgba(239, 68, 68, 0.15);
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  background: #fff;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pageInfo {
  color: #6b7280;
  font-size: 0.875rem;
}

.paginationButtons {
  display: flex;
  gap: 0.5rem;
}

.paginationButton {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  background: #fff;
  color: #374151;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
  font-weight: 500;
}

.paginationButton:hover:not(:disabled) {
  background: #f9fafb;
  border-color: #3b82f6;
  color: #3b82f6;
}

.paginationButton:disabled {
  background: #f9fafb;
  color: #9ca3af;
  border-color: #e5e7eb;
  cursor: not-allowed;
}

/* Loading State */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.loading {
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  color: #6b7280;
}

/* Error State */
.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.error {
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  color: #dc2626;
  text-align: center;
  max-width: 400px;
}

.retryButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #dc2626;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.retryButton:hover {
  background: #b91c1c;
}

.noProducts {
  text-align: center;
  padding: 4rem 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  color: #6b7280;
  font-size: 1.125rem;
}